import React from "react";
import "./home.css";
import Me from "../../assets/avatar-1.svg";
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown";
import Shapes from "./Shapes";
import ReactWhatsapp from 'react-whatsapp';

const Home = () => {
  return (
    <section className="home container" id="home">
      <div className="intro">
        <img src={Me} alt="" className="home__img" />
        <h1 className="home__name">Alia Sofa Maker</h1>
        <span className="home__education"> Crafting Comfort, Tailored to You </span>

        <HeaderSocials />

        <a href="tel:+917651936233" className="btn">
          Hire Us
        </a>
        
        <ReactWhatsapp number="+91-7651936233" class="btn btn-outline-primary" message="I am looking for sofa repair!!!">Whatsapp</ReactWhatsapp>

        <ScrollDown />
      </div>

      <Shapes />
    </section>
  );
};

export default Home;
