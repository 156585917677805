import React from "react";
import "./testimonials.css";
import Image1 from "../../assets/avatar-1.svg";
import Image3 from "../../assets/avatar-3.svg";
// import Swiper core and required modules
import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    id: 1,
    image: Image1,
    title: "Mr Krishna Murthy",
    subtitle: "IT Employee",
    comment:
      "From start to finish, the team at Alia Sofa Maker delivered exceptional service. Their skilled workers meticulously crafted our custom sofa, paying attention to every detail we requested. Not only did they exceed our expectations in craftsmanship, but they also provided efficient and friendly repair services when needed. We're grateful for their dedication and expertise, making our sofa experience truly exceptional. Good luck! 👍",
  },
  {
    id: 2,
    image: Image3,
    title: "Geeta Kumar",
    subtitle: "Doctor",
    comment:
      "Their skillful hands and keen eye for detail turned my worn-out sofa into a masterpiece, restoring its beauty and functionality beyond what I thought was possible. What truly stood out to me was their dedication to delivering exceptional results while ensuring a seamless and stress-free experience for me as a customer. I am incredibly grateful for their expertise and professionalism and would not hesitate to entrust them with any future sofa repair needs. Good luck! 👍",
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials container section">
      <h2 className="section__title">Clients & Reviews</h2>

      <Swiper
        className="testimonials__container grid"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
      >
        {data.map(({ id, image, title, subtitle, comment }) => {
          return (
            <SwiperSlide className="testimonial__item" key={id}>
              <div className="thumb">
                <img src={image} alt="" />
              </div>
              <h3 className="testimonials__title">{title}</h3>
              <span className="subtitle">{subtitle}</span>
              <div className="comment">{comment}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
