import Work1 from "../../assets/sofa-1.png";
import Work2 from "../../assets/sofa-2.png";
import Work3 from "../../assets/sofa-3.png";
import Work4 from "../../assets/sofa-4.png";
import Work5 from "../../assets/sofa-5.png";
import Work6 from "../../assets/sofa-6.png";
import Work7 from "../../assets/headboard-7.png";
import Work8 from "../../assets/work-10.png";
import Work9 from "../../assets/headboard-9.png";
import Work10 from "../../assets/headboard-8.png";
import Work11 from "../../assets/work-11.png";
import Work12 from "../../assets/work-12.png";
import Work13 from "../../assets/recliner-1.png";
import Work14 from "../../assets/recliner-2.png";
import Work15 from "../../assets/recliner-3.png";
import Work16 from "../../assets/recliner-4.png";
import Work17 from "../../assets/recliner-5.png";
import Work18 from "../../assets/recliner-6.png";
import Work19 from "../../assets/bed-1.png";
import Work20 from "../../assets/bed-2.png";
import Work21 from "../../assets/bed-3.png";
import Work22 from "../../assets/bed-4.png";
import Work23 from "../../assets/bed-5.png";
import Work24 from "../../assets/bed-6.png";
import Work25 from "../../assets/cfsofa-1.png";
import Work26 from "../../assets/cfsofa-2.png";
import Work27 from "../../assets/cfsofa-3.png";
import Work28 from "../../assets/cfsofa-4.png";
import Work29 from "../../assets/cfsofa-5.png";
import Work30 from "../../assets/cfsofa-6.png";
import Work31 from "../../assets/curtain-1.png";
import Work32 from "../../assets/curtain-2.png";
import Work33 from "../../assets/curtain-3.png";
import Work34 from "../../assets/curtain-4.png";
import Work35 from "../../assets/curtain-5.png";
import Work36 from "../../assets/curtain-6.png";

const Menu = [
  {
    id: 1,
    image: Work1,
    title: "SOFA SET",
    category: "sofa",
  },
  {
    id: 2,
    image: Work2,
    title: "SOFA SET",
    category: "sofa",
  },
  {
    id: 3,
    image: Work3,
    title: "SOFA SET",
    category: "sofa",
  },
  ,
  {
    id: 4,
    image: Work4,
    title: "SOFA SET",
    category: "sofa",
  },
  ,
  {
    id: 5,
    image: Work5,
    title: "SOFA SET",
    category: "sofa",
  },
  ,
  {
    id: 6,
    image: Work6,
    title: "SOFA SET",
    category: "sofa",
  },
  {
    id: 7,
    image: Work7,
    title: "HEADBOARD",
    category: "Board",
  },
  {
    id: 8,
    image: Work8,
    title: "HEADBOARD",
    category: "Board",
  },
  {
    id: 9,
    image: Work9,
    title: "HEADBOARD",
    category: "Board",
  },
  {
    id: 10,
    image: Work10,
    title: "HEADBOARD",
    category: "Board",
  },
  {
    id: 11,
    image: Work11,
    title: "HEADBOARD",
    category: "Board",
  },
  {
    id: 12,
    image: Work12,
    title: "HEADBOARD",
    category: "Board",
  },
  {
    id: 13,
    image: Work13,
    title: "RECLINER",
    category: "Recliner",
  },
  {
    id: 14,
    image: Work14,
    title: "RECLINER",
    category: "Recliner",
  },
  {
    id: 15,
    image: Work15,
    title: "RECLINER",
    category: "Recliner",
  },
  {
    id: 16,
    image: Work16,
    title: "RECLINER",
    category: "Recliner",
  },
  {
    id: 17,
    image: Work17,
    title: "RECLINER",
    category: "Recliner",
  },
  {
    id: 18,
    image: Work18,
    title: "RECLINER",
    category: "Recliner",
  },
  {
    id: 19,
    image: Work19,
    title: "SOFA CUM BED",
    category: "Bed",
  },
  {
    id: 20,
    image: Work20,
    title: "SOFA CUM BED",
    category: "Bed",
  },
  {
    id: 21,
    image: Work21,
    title: "SOFA CUM BED",
    category: "Bed",
  },
  {
    id: 22,
    image: Work22,
    title: "SOFA CUM BED",
    category: "Bed",
  },
  {
    id: 23,
    image: Work23,
    title: "SOFA CUM BED",
    category: "Bed",
  },
  {
    id: 24,
    image: Work24,
    title: "SOFA CUM BED",
    category: "Bed",
  },
  {
    id: 25,
    image: Work25,
    title: "Chesterfield ",
    category: "Chesterfield ",
  },
  {
    id: 26,
    image: Work26,
    title: "Chesterfield ",
    category: "Chesterfield",
  },
  {
    id: 27,
    image: Work27,
    title: "Chesterfield ",
    category: "Chesterfield ",
  },
  {
    id: 28,
    image: Work28,
    title: "Chesterfield",
    category: "Chesterfield ",
  },
  {
    id: 29,
    image: Work29,
    title: "Chesterfield ",
    category: "Chesterfield ",
  },
  {
    id: 30,
    image: Work30,
    title: "Chesterfield",
    category: "Chesterfield",
  },
  {
    id: 31,
    image: Work31,
    title: "Curtains",
    category: "Curtains",
  },{
    id: 32,
    image: Work32,
    title: "Curtains",
    category: "Curtains",
  },{
    id: 33,
    image: Work33,
    title: "Curtains",
    category: "Curtains",
  },{
    id: 34,
    image: Work34,
    title: "Curtains",
    category: "Curtains",
  },{
    id: 35,
    image: Work35,
    title: "Curtains",
    category: "Curtains",
  },{
    id: 36,
    image: Work36,
    title: "Curtains",
    category: "Curtains",
  },
];

export default Menu;
