import React from "react";
import "./pricing.css";
import Image1 from "../../assets/price-1.svg";
import Image2 from "../../assets/price-2.svg";
import Image3 from "../../assets/price-3.svg";

const Pricing = () => {
  return (
    <section className="pricing container section">
      <h2 className="section__title">Our Affordable Pricing </h2>

      <div className="pricing__container grid">
        <div className="pricing__item">
          <img src={Image1} alt="" className="pricing__img" />
          <h3 className="pricing__plan">Economy</h3>
          <p className="pricing__title">
          Transform your space instantly, tailored to your taste and delivered with ease.
          </p>
          <p className="pricing__support">Contact Us</p>
          <h3 className="price">
            <em>Starting</em>30000 <span>onwards</span>
          </h3>
          <a href="tel:+917651936233" className="btn">
            Order Now
          </a>
        </div>

        <div className="pricing__item best">
          <span className="badge">Recommended</span>
          <img src={Image2} alt="" className="pricing__img" />
          <h3 className="pricing__plan">Premium</h3>
          <p className="pricing__title">
          Designed to perfection and delivered to your doorstep within 7 days of your order.
          </p>
          <p className="pricing__support">Contact Us</p>
          <h3 className="price">
            <em></em> <span></span>
          </h3>
          <a href="tel:+917651936233" className="btn">
            Get Quote
          </a>
        </div>

        <div className="pricing__item">
          <img src={Image3} alt="" className="pricing__img" />
          <h3 className="pricing__plan">Luxury</h3>
          <p className="pricing__title">
          Indulge in the ultimate comfort and style with our bespoke luxury sofas, meticulously customized to your preferences.
          </p>
          <p className="pricing__support">Contact Us </p>
          <h3 className="price">
            <em></em>  <span></span>
          </h3>
          <a href="tel:+917651936233" className="btn">
            Get Quote
          </a>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
